/* eslint-disable max-len */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Grid, Link, makeStyles } from '@material-ui/core'
import Logo from '../../shared/imgs/farol-logo-novo.svg'
import MenuMobile from '../MenuMobile'
import { useLoading, useResponsiveInfo } from '../../shared/hooks'
import styles from './Header.style'
import LoggedUserInfo from './components/LoggedUserInfo/LoggedUserInfo'
import { ApplicationTypeContext, AuthContext } from '../../contexts'
import HeaderMenu from './components/HeaderMenu/HeaderMenu'
import { getSelectedProfile } from '../../utils/user/profileHelper'
import { PROFILES } from '../../shared'
import {
  getNetworkManagerMenuOptions,
  getResearcherMenuOptions,
  getRegionalCoordinatorMenuOptions,
  getSchoolManagerMenuOptions,
  getTeacherMenuOptions,
  getExamineeMenuOptions,
  getPartnershipCollaboratorMenuOptions,
  getSennaAdultsResponderMenuOptions,
  getAdminConexiaMenuOptions,
  getAdminFarolMenuOptions,
  navigateToOption,
  getAdminFtdMenuOptions,
} from './components/utils/HeaderMenuUtils'
import UserService from '../../services/UserService'
import applicationTabs from '../../pages/ApplicationList/components/TabApplications/utils/applicationTabs'
import { ReportTypeContext } from '../../contexts/ReportLibraryTypeContext'

const useStyles = makeStyles(styles)

export default function Header({ history }) {
  const { isDesktop, isSmallDesktop } = useResponsiveInfo()
  const {
    currentUser,
    isSpecialNetworkManager,
    isAdmFarol,
    isConexia,
    isAdmFtd,
    activeAssessmentsInfo,
    partCollaboratorHasStartedAssessments,
  } = useContext(AuthContext)
  const { updateStateType } = useContext(ApplicationTypeContext)
  const { updateStateTypeReport } = useContext(ReportTypeContext)
  const { showLoading, hideLoading } = useLoading()
  const classes = useStyles()
  const [menuOptions, setMenuOptions] = useState([])

  const getTabFromActiveAssessmentsInfo = useCallback(() => {
    const isRegionalCoordinator = getSelectedProfile().id === 8
    const isSennaAdultsResponder = getSelectedProfile().role === 'RESPONDER'
    let correctTab = isRegionalCoordinator ? applicationTabs.WORK_WORLD_TOOL : applicationTabs.SENNA
    if (activeAssessmentsInfo.hasActiveSennaKids) {
      correctTab = applicationTabs.SENNA_KIDS
    }
    if (activeAssessmentsInfo.hasActiveWorkWorldTool) {
      correctTab = applicationTabs.WORK_WORLD_TOOL
    }
    if (activeAssessmentsInfo.hasActiveDialog && !isRegionalCoordinator) {
      correctTab = applicationTabs.DIALOGUES
    }
    if (activeAssessmentsInfo.hasActiveSenna && !isRegionalCoordinator) {
      correctTab = applicationTabs.SENNA
    }
    if (isSennaAdultsResponder || activeAssessmentsInfo.hasActiveSennaAdults) {
      correctTab = applicationTabs.SENNA_ADULT
    }

    return correctTab
  }, [activeAssessmentsInfo])

  const handleApplicationRoutingByRole = useCallback(() => {
    const roles = currentUser?.user?.profiles[0]?.role
    switch (roles) {
      case 'ROLE_PARTNERSHIP_COLLABORATOR':
        return history.push('/aplicacoes-respondente', {
          hasStartedAssessments: partCollaboratorHasStartedAssessments,
        })
      case 'ROLE_RESEARCHER':
        return history.push('/aplicacoes-pesquisador')
      case 'ROLE_EXAMINEE':
        updateStateType(getTabFromActiveAssessmentsInfo())
        return history.push('/aplicacoes-estudante')
      case 'ROLE_RESPONDER':
        updateStateType(getTabFromActiveAssessmentsInfo())
        return history.push('/aplicacoes-respondente-sa')
      default:
        updateStateType(getTabFromActiveAssessmentsInfo())
        return history.push('/aplicacoes')
    }
  }, [
    currentUser,
    history,
    partCollaboratorHasStartedAssessments,
    updateStateType,
    getTabFromActiveAssessmentsInfo,
  ])

  const getLoggedMenuByRole = useCallback(async () => {
    const profile = getSelectedProfile()?.description
    switch (profile) {
      case PROFILES.NETWORK_MANAGER.description:
        return getNetworkManagerMenuOptions(
          history,
          updateStateType,
          updateStateTypeReport,
          isSpecialNetworkManager,
          activeAssessmentsInfo,
        )
      case PROFILES.REGIONAL_COORDINATOR.description:
        return getRegionalCoordinatorMenuOptions(history, updateStateType, activeAssessmentsInfo)
      case PROFILES.SCHOOL_MANAGER.description:
        return getSchoolManagerMenuOptions(history, updateStateType)
      case PROFILES.TEACHER.description:
        return getTeacherMenuOptions(history, updateStateType)
      case PROFILES.EXAMINEE.description:
        return getExamineeMenuOptions(history, updateStateType)
      case PROFILES.PARTNERSHIP_COLLABORATOR.description:
        return getPartnershipCollaboratorMenuOptions(history, partCollaboratorHasStartedAssessments)
      case PROFILES.RESPONDER.description:
        return getSennaAdultsResponderMenuOptions(history)
      case PROFILES.ADMINISTRATOR.description:
        if (isAdmFarol !== undefined && isAdmFarol) {
          return getAdminFarolMenuOptions(history)
        }
        if (isAdmFtd !== undefined && isAdmFtd) {
          return getAdminFtdMenuOptions(history)
        }
        if (isConexia) {
          return getAdminConexiaMenuOptions(history)
        }
        break
      case PROFILES.RESEARCHER.description:
        return getResearcherMenuOptions(history)
      default:
        return []
    }
    return []
  }, [
    activeAssessmentsInfo,
    history,
    isAdmFarol,
    isConexia,
    isAdmFtd,
    isSpecialNetworkManager,
    partCollaboratorHasStartedAssessments,
    updateStateType,
    updateStateTypeReport,
  ])

  const getLoggedMenuByRoleMobile = useCallback(async () => {
    const profile = getSelectedProfile()?.description
    const assessmentsOption = {
      title: 'Avaliações',
      children: [],
      action: () => {
        handleApplicationRoutingByRole()
      },
      highlightPaths: [],
    }
    const reportLibraryOption = {
      title: 'Relatórios',
      children: [],
      action: () => {
        navigateToOption('/biblioteca-relatorios', history, null)
      },
      highlightPaths: [],
    }
    switch (profile) {
      case PROFILES.NETWORK_MANAGER.description:
        return [assessmentsOption, reportLibraryOption]
      case PROFILES.REGIONAL_COORDINATOR.description:
        return [assessmentsOption, reportLibraryOption]
      case PROFILES.SCHOOL_MANAGER.description:
        return [assessmentsOption, reportLibraryOption]
      case PROFILES.TEACHER.description:
        return [assessmentsOption, reportLibraryOption]
      case PROFILES.EXAMINEE.description: {
        const examineeOptions = [assessmentsOption]
        if (activeAssessmentsInfo?.hasAnyWorkWorldTool) {
          examineeOptions.push(reportLibraryOption)
        }
        return examineeOptions
      }
      case PROFILES.PARTNERSHIP_COLLABORATOR.description:
        return [assessmentsOption, reportLibraryOption]
      case PROFILES.RESPONDER.description:
        return [assessmentsOption]
      case PROFILES.RESEARCHER.description:
        return [assessmentsOption]
      default:
        return []
    }
  }, [activeAssessmentsInfo, handleApplicationRoutingByRole, history])

  const mountMenu = async () => {
    showLoading()
    const loggedUserProfileMenuOptions = isDesktop
      ? await getLoggedMenuByRole()
      : await getLoggedMenuByRoleMobile()
    const generalMenuOptions = [
      {
        title: 'Início',
        action: () => {
          history.push('/')
        },
        highlightPaths: ['/dashboard', '/'],
        children: [],
      },
      ...loggedUserProfileMenuOptions,
      {
        title: 'Sair',
        action: () => {
          UserService.forceLogout()
        },
        children: [],
        highlightPaths: [],
      },
    ]
    setMenuOptions(generalMenuOptions)
    hideLoading()
  }

  useEffect(() => {
    if (currentUser !== undefined) {
      mountMenu()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUser,
    isAdmFarol,
    isAdmFtd,
    isConexia,
    partCollaboratorHasStartedAssessments,
    activeAssessmentsInfo,
    isDesktop,
  ])

  return (
    <div className={classes.container}>
      <Link href="/">
        <img
          alt="Logo Senna"
          src={Logo}
          className={!isDesktop ? classes.logo : classes.logoDesktop}
        />
      </Link>
      {isDesktop && (
        <>
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              gap: isSmallDesktop ? 4 : 10,
            }}
          >
            <HeaderMenu history={history} menuOptions={menuOptions} />
          </Grid>
          {currentUser && <LoggedUserInfo />}
        </>
      )}
      {!isDesktop && (
        <div className={classes.textContainer}>
          <MenuMobile menuClasses={classes} menuOptions={menuOptions} />
        </div>
      )}
    </div>
  )
}
