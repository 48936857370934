/* eslint-disable max-len */
// eslint-disable-next-line max-len
import applicationTabs from '../../../../pages/ApplicationList/components/TabApplications/utils/applicationTabs'
import reportLibraryTabs from '../../../../pages/ReportLibrary/components/TabComponent/utils/reportLibraryTabs'

export const navigateToOption = (route, history, state) => {
  window.scrollTo(0, 0, 'smooth')
  history.push(route, state)
}

export const getNetworkManagerMenuOptions = (
  history,
  updateStateType,
  updateStateTypeReport,
  isSpecialNetworkManager,
  activeAssessments,
) => {
  if (isSpecialNetworkManager !== undefined && activeAssessments !== undefined) {
    const cadastrosChildren = [
      {
        label: 'Regionais e escola',
        action: () => navigateToOption('/regionais', history, null),
        subChildren: [],
      },
      {
        label: 'Coordenadores de regionais',
        action: () => navigateToOption('/coordenadores', history, null),
        subChildren: [],
      },
      {
        label: 'Gestores escolares',
        action: () => navigateToOption('/gestor-escolar', history, null),
        subChildren: [
          {
            label: 'Cadastro único',
            action: () => navigateToOption('/cadastro/gestor', history, null),
          },
          {
            label: 'Cadastro em lote',
            action: () =>
              navigateToOption('/importar-dados-cadastrais-network-manager', history, null),
          },
        ],
      },
      {
        label: 'Professores',
        action: () => navigateToOption('/professores', history, null),
        subChildren: [
          {
            label: 'Cadastro único',
            action: () => navigateToOption('/cadastro/professor', history, null),
          },
          {
            label: 'Cadastro em lote',
            action: () => navigateToOption('/importar-dados-cadastrais-teacher', history, null),
          },
        ],
      },
      {
        label: 'Turmas',
        action: () => navigateToOption('/turmas', history, null),
        subChildren: [
          {
            label: 'Cadastro único',
            action: () => navigateToOption('/gestor/turmas/cadastrar/', history, null),
          },
          {
            label: 'Cadastro em lote',
            action: () => navigateToOption('/importar-dados-cadastrais-classroom', history, null),
          },
        ],
      },
      {
        label: 'Estudantes',
        action: () => navigateToOption('/estudantes', history, null),
        subChildren: [
          {
            label: 'Cadastro único',
            action: () => navigateToOption('/estudantes/cadastrar', history, null),
          },
          {
            label: 'Cadastro em lote',
            action: () => navigateToOption('/importar-dados-cadastrais-examinee', history, null),
          },
        ],
      },
    ]

    const cadastrosExternosChildren = [
      {
        label: 'Unidade',
        action: () => navigateToOption('/unidades', history, null),
        subChildren: [],
      },
    ]

    const coletasChildren = [
      {
        label: 'Avaliações',
        action: () => {},
        subChildren: [
          {
            label: 'Senna',
            action: () => {
              updateStateType(applicationTabs.SENNA)
              navigateToOption('/aplicacoes', history, null)
            },
          },
          {
            label: 'Diálogos Socioemocionais',
            action: () => {
              updateStateType(applicationTabs.DIALOGUES)
              navigateToOption('/aplicacoes', history, null)
            },
          },
          {
            label: 'Realizando escolhas',
            action: () => {
              updateStateType(applicationTabs.WORK_WORLD_TOOL)
              navigateToOption('/aplicacoes', history, null)
            },
          },
          {
            label: 'Senna para crianças',
            action: () => {
              updateStateType(applicationTabs.SENNA_KIDS)
              navigateToOption('/aplicacoes', history, null)
            },
          },
          {
            label: 'Senna adultos',
            action: () => {
              updateStateType(applicationTabs.SENNA_ADULT)
              navigateToOption('/aplicacoes', history, null)
            },
          },
        ],
      },
    ]

    if (isSpecialNetworkManager) {
      coletasChildren.push({
        label: 'Programas educacionais',
        action: () => navigateToOption('/programas', history, null),
        subChildren: [],
      })
      coletasChildren.push({
        label: 'Dados de acompanhamento',
        action: () => {},
        subChildren: [
          {
            label: 'Cadastro único',
            action: () => {
              navigateToOption('/importar-dados-acompanhamento-manual', history, null)
            },
          },
          {
            label: 'Importação em lote',
            action: () => {
              navigateToOption('/importar-dados-acompanhamento', history, null)
            },
          },
        ],
      })
      const reportSubChildren = []
      if (activeAssessments.hasActiveSennaKids || activeAssessments.hasActiveWorkWorldTool) {
        reportSubChildren.push({
          label: 'Instrumento',
          action: () => {
            updateStateTypeReport(reportLibraryTabs.INSTRUMENTOS)
            navigateToOption('/biblioteca-relatorios', history, null)
          },
        })
      }

      reportSubChildren.push({
        label: 'Gestão e desempenho escolar',
        action: () => {
          updateStateTypeReport(reportLibraryTabs.DESEMPENHO_ESCOLAR)
          navigateToOption('/biblioteca-relatorios', history, null)
        },
      })

      coletasChildren.push({
        label: 'Biblioteca de relatórios',
        action: () => {},
        subChildren: reportSubChildren,
      })
    } else {
      coletasChildren.push({
        label: 'Biblioteca de relatórios',
        action: () => {},
        subChildren: [
          {
            label: 'Instrumento',
            action: () => {
              navigateToOption('/biblioteca-relatorios', history, null)
            },
          },
        ],
      })
    }

    if (activeAssessments.hasActiveDialog) {
      coletasChildren.push({
        label: 'Relatórios',
        action: () => navigateToOption('/relatorios', history, null),
        subChildren: [],
      })
    }

    return [
      {
        title: 'Cadastros',
        children: cadastrosChildren,
        action: () => {},
        highlightPaths: [
          '/regionais',
          '/coordenadores',
          '/professores',
          '/professor',
          '/turmas',
          '/estudantes',
          '/gestor-escolar',
          '/cadastro/gestor',
          '/coordenadores/cadastrar',
          '/importar-dados-cadastrais-network-manager',
          '/cadastro/professor',
          '/importar-dados-cadastrais-teacher',
          '/gestor/turmas/cadastrar',
          '/importar-dados-cadastrais-classroom',
          '/estudantes/cadastrar',
          '/importar-dados-cadastrais-examinee',
        ],
      },
      {
        title: 'Cadastros externos',
        children: cadastrosExternosChildren,
        action: () => {},
        highlightPaths: ['/unidades', '/cadastrar-unidade'],
      },
      {
        title: 'Coletas e acompanhamentos',
        children: coletasChildren,
        action: () => {},
        highlightPaths: [
          '/aplicacoes',
          '/cadastrar-aplicacao',
          '/editar-aplicacao-s',
          '/cadastrar-aplicacao-dialogos',
          '/editar-aplicacao-d',
          '/coletas',
          '/cadastrar-aplicacao-mundo-trabalho',
          '/editar-aplicacao-m',
          '/cadastrar-aplicacao-senna-para-criancas',
          '/editar-aplicacao-k',
          '/coleta-senna-kids',
          '/programas',
          '/importar-dados-acompanhamento-manual',
          '/importar-dados-acompanhamento',
          '/biblioteca-relatorios',
          '/relatorios',
        ],
      },
    ]
  }

  return []
}

export const getResearcherMenuOptions = history => {
  return [
    {
      title: 'Avaliações',
      children: [],
      action: () => navigateToOption('/aplicacoes-pesquisador', history, null),
      highlightPaths: ['/aplicacoes-pesquisador', '/cadastrar-aplicacoes-pesquisador'],
    },
    {
      title: 'Instrumentos',
      children: [],
      action: () => navigateToOption('/instrumentos', history, null),
      highlightPaths: ['/instrumentos', '/instrumentos/cadastrar'],
    },
    {
      title: 'Parcerias',
      children: [],
      action: () => navigateToOption('/parceria', history, null),
      highlightPaths: ['/parceria', '/cadastrar-parceria', '/editar-parceria'],
    },
    {
      title: 'Respondentes',
      children: [
        {
          label: 'Cadastro único',
          action: () => navigateToOption('/respondentes/cadastrar', history, null),
          subChildren: [],
        },
        {
          label: 'Cadastro em lote',
          action: () => navigateToOption('/importar-respondente', history, null),
          subChildren: [],
        },
      ],
      action: () => {},
      highlightPaths: ['/respondentes/cadastrar', '/importar-respondente'],
    },
    {
      title: 'Chaves de resposta',
      children: [],
      action: () => navigateToOption('/chave-resposta', history, null),
      highlightPaths: ['/chave-resposta', '/chave-resposta/registrar'],
    },
    {
      title: 'Itens',
      children: [],
      action: () => navigateToOption('/itens', history, null),
      highlightPaths: ['/itens', '/cadastrar-item', '/editar-item'],
    },
    {
      title: 'Cadernos',
      children: [],
      action: () => navigateToOption('/cadernos', history, null),
      highlightPaths: ['/cadernos', '/cadernos/cadastrar', '/cadernos/detalhes'],
    },
  ]
}

export const getRegionalCoordinatorMenuOptions = (history, updateStateType, activeAssessments) => {
  if (activeAssessments !== undefined) {
    const coletasChildren = [
      {
        label: 'Avaliações',
        action: () => {},
        subChildren: [
          {
            label: 'Realizando escolhas',
            action: () => {
              updateStateType(applicationTabs.WORK_WORLD_TOOL)
              navigateToOption('/aplicacoes', history, null)
            },
          },
          {
            label: 'Senna para crianças',
            action: () => {
              updateStateType(applicationTabs.SENNA_KIDS)
              navigateToOption('/aplicacoes', history, null)
            },
          },
        ],
      },
      {
        label: 'Acompanhamentos',
        action: () => navigateToOption('/acompanhamentos', history, null),
        subChildren: [],
      },
      {
        label: 'Biblioteca de relatórios',
        action: () => {},
        subChildren: [
          {
            label: 'Instrumento',
            action: () => {
              navigateToOption('/biblioteca-relatorios', history, null)
            },
          },
        ],
      },
    ]

    if (activeAssessments.hasActiveDialog) {
      coletasChildren.push({
        label: 'Relatórios',
        action: () => navigateToOption('/relatorios', history, null),
        subChildren: [],
      })
    }
    return [
      {
        title: 'Coletas e acompanhamentos',
        children: coletasChildren,
        action: () => {},
        highlightPaths: [
          '/aplicacoes',
          '/biblioteca-relatorios',
          '/coleta-senna-kids',
          '/acompanhamentos',
          '/acompanhamentos/registrar',
          '/relatorios',
        ],
      },
    ]
  }

  return []
}

export const getSchoolManagerMenuOptions = (history, updateStateType) => {
  const cadastrosChildren = [
    {
      label: 'Professores',
      action: () => navigateToOption('/professores', history, null),
      subChildren: [],
    },
    {
      label: 'Turmas',
      action: () => navigateToOption('/turmas', history, null),
      subChildren: [],
    },
    {
      label: 'Estudantes',
      action: () => navigateToOption('/estudantes', history, null),
      subChildren: [],
    },
  ]
  const coletasChildren = [
    {
      label: 'Avaliações',
      action: () => {},
      subChildren: [
        {
          label: 'Senna',
          action: () => {
            updateStateType(applicationTabs.SENNA)
            navigateToOption('/aplicacoes', history, null)
          },
        },
        {
          label: 'Diálogos Socioemocionais',
          action: () => {
            updateStateType(applicationTabs.DIALOGUES)
            navigateToOption('/aplicacoes', history, null)
          },
        },
        {
          label: 'Realizando escolhas',
          action: () => {
            updateStateType(applicationTabs.WORK_WORLD_TOOL)
            navigateToOption('/aplicacoes', history, null)
          },
        },
        {
          label: 'Senna para crianças',
          action: () => {
            updateStateType(applicationTabs.SENNA_KIDS)
            navigateToOption('/aplicacoes', history, null)
          },
        },
      ],
    },
    {
      label: 'Biblioteca de relatórios',
      action: () => {},
      subChildren: [
        {
          label: 'Instrumento',
          action: () => {
            navigateToOption('/biblioteca-relatorios', history, null)
          },
        },
      ],
    },
  ]
  return [
    {
      title: 'Cadastros',
      children: cadastrosChildren,
      action: () => {},
      highlightPaths: [
        '/professores',
        '/turmas',
        '/estudantes',
        '/cadastro/professor',
        '/professor',
        '/importar-dados-cadastrais-teacher',
        '/gestor/turmas/cadastrar/',
        '/importar-dados-cadastrais-classroom',
        '/estudantes/cadastrar',
        '/importar-dados-cadastrais-examinee',
      ],
    },
    {
      title: 'Coletas e acompanhamentos',
      children: coletasChildren,
      action: () => {},
      highlightPaths: [
        '/aplicacoes',
        '/coletas',
        '/coleta-senna-kids',
        '/biblioteca-relatorios',
        '/relatorios',
      ],
    },
  ]
}

export const getTeacherMenuOptions = (history, updateStateType) => {
  const cadastrosChildren = [
    {
      label: 'Turmas',
      action: () => navigateToOption('/turmas', history, null),
      subChildren: [],
    },
    {
      label: 'Estudantes',
      action: () => navigateToOption('/estudantes', history, null),
      subChildren: [],
    },
  ]
  const coletasChildren = [
    {
      label: 'Avaliações',
      action: () => {},
      subChildren: [
        {
          label: 'Senna',
          action: () => {
            updateStateType(applicationTabs.SENNA)
            navigateToOption('/aplicacoes', history, null)
          },
        },
        {
          label: 'Diálogos Socioemocionais',
          action: () => {
            updateStateType(applicationTabs.DIALOGUES)
            navigateToOption('/aplicacoes', history, null)
          },
        },
        {
          label: 'Realizando escolhas',
          action: () => {
            updateStateType(applicationTabs.WORK_WORLD_TOOL)
            navigateToOption('/aplicacoes', history, null)
          },
        },
        {
          label: 'Senna para crianças',
          action: () => {
            updateStateType(applicationTabs.SENNA_KIDS)
            navigateToOption('/aplicacoes', history, null)
          },
        },
      ],
    },
    {
      label: 'Biblioteca de relatórios',
      action: () => {},
      subChildren: [
        {
          label: 'Instrumento',
          action: () => {
            navigateToOption('/biblioteca-relatorios', history, null)
          },
        },
      ],
    },
  ]
  return [
    {
      title: 'Cadastros',
      children: cadastrosChildren,
      action: () => {},
      highlightPaths: [
        '/turmas',
        '/estudantes',
        '/gestor/turmas/cadastrar/',
        '/importar-dados-cadastrais-classroom',
        '/estudantes/cadastrar',
        '/importar-dados-cadastrais-examinee',
      ],
    },
    {
      title: 'Coletas e acompanhamentos',
      children: coletasChildren,
      action: () => {},
      highlightPaths: [
        '/aplicacoes',
        '/coletas',
        '/coleta-senna-kids',
        '/biblioteca-relatorios',
        '/relatorios',
      ],
    },
  ]
}

export const getExamineeMenuOptions = (history, updateStateType) => {
  const coletasChildren = [
    {
      label: 'Avaliações',
      action: () => {},
      subChildren: [
        {
          label: 'Senna',
          action: () => {
            updateStateType(applicationTabs.SENNA)
            navigateToOption('/aplicacoes-estudante', history, null)
          },
        },
        {
          label: 'Diálogos Socioemocionais',
          action: () => {
            updateStateType(applicationTabs.DIALOGUES)
            navigateToOption('/aplicacoes-estudante', history, null)
          },
        },
        {
          label: 'Realizando escolhas',
          action: () => {
            updateStateType(applicationTabs.WORK_WORLD_TOOL)
            navigateToOption('/aplicacoes-estudante', history, null)
          },
        },
        {
          label: 'Senna para crianças',
          action: () => {
            updateStateType(applicationTabs.SENNA_KIDS)
            navigateToOption('/aplicacoes-estudante', history, null)
          },
        },
      ],
    },
    {
      label: 'Biblioteca de relatórios',
      action: () => {},
      subChildren: [
        {
          label: 'Instrumento',
          action: () => {
            navigateToOption('/biblioteca-relatorios', history, null)
          },
        },
      ],
    },
  ]
  return [
    {
      title: 'Coletas e acompanhamentos',
      children: coletasChildren,
      action: () => {},
      highlightPaths: ['/aplicacoes-estudante', '/biblioteca-relatorios'],
    },
  ]
}

export const getPartnershipCollaboratorMenuOptions = (
  history,
  partCollaboratorHasStartedAssessments,
) => {
  const coletasChildren = [
    {
      label: 'Avaliações',
      action: () => {
        navigateToOption('/aplicacoes-respondente', history, {
          hasStartedAssessments: partCollaboratorHasStartedAssessments,
        })
      },
      subChildren: [],
    },
    {
      label: 'Biblioteca de relatórios',
      action: () => navigateToOption('/biblioteca-relatorios', history, null),
      subChildren: [],
    },
  ]
  return [
    {
      title: 'Coletas e acompanhamentos',
      children: coletasChildren,
      action: () => {},
      highlightPaths: ['/aplicacoes-respondente', '/biblioteca-relatorios'],
    },
  ]
}

export const getSennaAdultsResponderMenuOptions = history => {
  const coletasChildren = [
    {
      label: 'Avaliações',
      action: () => navigateToOption('/aplicacoes-respondente-sa', history, null),
      subChildren: [],
    },
  ]
  return [
    {
      title: 'Coletas e acompanhamentos',
      children: coletasChildren,
      action: () => {},
      highlightPaths: ['/aplicacoes-respondente-sa'],
    },
  ]
}

export const getAdminConexiaMenuOptions = history => {
  return [
    {
      title: 'Integração Manual',
      children: [],
      action: () => navigateToOption('/integracao-manual', history, null),
      highlightPaths: ['/integracao-manual'],
    },
    {
      title: 'Relatórios de Integração',
      children: [],
      action: () => navigateToOption('/relatorios-integracao', history, null),
      highlightPaths: ['/relatorios-integracao'],
    },
  ]
}

export const getAdminFarolMenuOptions = history => {
  return [
    {
      title: 'Agentes Técnicos e Gestores',
      children: [],
      action: () => navigateToOption('/admin/agentes-tecnicos-gestores', history, null),
      highlightPaths: ['/admin/agentes-tecnicos-gestores'],
    },
  ]
}

export const getAdminFtdMenuOptions = history => {
  return [
    {
      title: 'Integração Manual',
      children: [],
      action: () => navigateToOption('/admin-ftd/integracao-manual', history, null),
      highlightPaths: ['/admin-ftd/integracao-manual'],
    },
    {
      title: 'Relatório de Integração',
      children: [],
      action: () => navigateToOption('/admin-ftd/relatorio-integracao', history, null),
      highlightPaths: ['/admin-ftd/relatorio-integracao'],
    },
  ]
}
