import { useEffect, useState, useContext } from 'react'

import { PROFILES } from '../../../shared'
import { useSnackbar } from '../../../shared/hooks'
import { getData } from '../../../utils/fetchData/fetchData'
import AuthContext from '../../../contexts/AuthContext'
import { FilterContext } from '../../../contexts/FilterContext'

import { pageSizeOptions } from '../constants'

const useGetClassrooms = () => {
  const [data, setData] = useState({ content: [] })
  const [updateTable, setUpdateTable] = useState(false)
  const [firstAccessPage, setFirstAccessPage] = useState(true)
  const [filterForm, setFilterForm] = useState({
    page: 0,
    size: pageSizeOptions[0],
    schoolIds: null,
    classroomIds: null,
    teachingNetworkIds: null,
    status: null,
    gradeIds: null,
    year: null,
    sort: null,
  })
  const [isLoading, setLoading] = useState(false)

  const { snackbarError } = useSnackbar()
  const { currentUser } = useContext(AuthContext)
  const { filters } = useContext(FilterContext)

  const filterFormInitialValues = filters.filter_programs
  const paramsContext = {
    schoolIds: filterFormInitialValues.schools
      ? filterFormInitialValues.schools.map(s => s.id).join(',')
      : [],
    classroomIds: filterFormInitialValues.classrooms
      ? filterFormInitialValues.classrooms.map(s => s.id).join(',')
      : [],
    teachingNetworkIds: filterFormInitialValues.teachingNetworks
      ? filterFormInitialValues.teachingNetworks.map(s => s.id).join(',')
      : [],
    status: filterFormInitialValues.status ? filterFormInitialValues.status.value : null,
    gradeIds: filterFormInitialValues.grades
      ? filterFormInitialValues.grades.map(s => s.id).join(',')
      : [],
    yearListId: filterFormInitialValues.year
      ? filterFormInitialValues.year.map(s => s.id).join(',')
      : [],
  }
  const getClassroomsByFilters = async () => {
    const sort = filterForm.sort ? Object.values(filterForm.sort).join(',') : undefined
    const { id } = currentUser.teachingNetwork
    const params = {
      profileId: PROFILES.NETWORK_MANAGER.id,
      userTeachingNetworkId: id,
      page: filterForm.page,
      size: filterForm.size,
      schoolIds: paramsContext.schoolIds || filterForm.schoolIds || undefined,
      classroomIds: paramsContext.classroomIds || filterForm.classroomIds || undefined,
      teachingNetworkIds:
        paramsContext.teachingNetworkIds || filterForm.teachingNetworkIds || undefined,
      yearListId: paramsContext.yearListId || filterForm.yearListId || undefined,
      status: paramsContext.status || filterForm.status,
      gradeIds: paramsContext.gradeIds || filterForm.gradeIds || undefined,
      sort,
    }

    const requestParams = {
      url: 'program/filter/classroom',
      options: { params },
      snackbarError,
      setLoading,
    }

    const responseData = await getData(requestParams)
    const classroomsList = responseData?.content?.map(classroom => {
      const hasLinkedPrograms = Array.isArray(classroom.programs)
      const linkedPrograms = hasLinkedPrograms
        ? classroom.programs.map(program => ({
            id: Number(program.id),
            name: String(program.name),
          }))
        : []

      return {
        id: classroom.id,
        schoolName: classroom.school.name,
        classroomName: classroom.name,
        gradeName: classroom.grade.name,
        gradeOrder: classroom.grade.ordem,
        schoolShiftName: classroom.schoolShift.name,
        isProgramLinked: classroom.programLinked,
        linkedPrograms,
        haveAnswers: classroom.haveAnswers,
        excessJustifications: classroom.excessJustifications,
        schoolYear: classroom.schoolYear,
      }
    })

    setData({ ...responseData, content: classroomsList })
    setFirstAccessPage(true)
  }

  useEffect(() => {
    if (firstAccessPage) {
      getClassroomsByFilters()
      setFirstAccessPage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTable])

  return {
    isLoading,
    data,
    setFilterForm,
    setUpdateTable,
    filterForm,
    getClassroomsByFilters,
  }
}

export { useGetClassrooms }
